@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.section3 {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .section3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.section3-content {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
}

.section3-left {
    width: 500px;
    text-align: center;
    padding: 1rem;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
    -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
    box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.section3-left img {
    width: 100%;
    border-radius: 5px;
}

.section3-right {
    width: 600px;
    margin: auto;
    padding: 1rem;
}


.section3-right h2 {
    font-size: 2rem;
    padding: 1rem 0;
}

.section3-right h1 {
    text-transform: uppercase;
    color: var(--primary-dark);
    font-size: 1rem;
    letter-spacing: 8px; 
}

.section3 hr {
    width: 40%;
    height: 2px;
    background-color: #ebae39;
    border: none;
    border-radius: 1px;
    position: absolute;
    right: 35%;
}


@media screen and (max-width:940px) {

    .section3-content {
        flex-wrap: wrap;
    }

    .section3-left {
        width: 80%;
    }

    .section3-right {
        width: 90%;
    }

    .section3-right h1 {
        text-align: center; 
    }

    .section3-right h2 {
        text-align: center;
        font-size: 1.5rem;
    }

    .section3 hr {
        display: none;
    }

}
