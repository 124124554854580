.cognitive {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;

  }
  
  .cognitive:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .cognitive-content {
    padding: 1rem;
  }

  .cognitive-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .cognitive-right {
    width: 50%;
    margin: auto;
  }

  .cognitive-right h1 {
    padding: .5rem 0;
    text-align: center;
  } 
  .cognitive-right p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
    text-align: justify;
  }

  .cognitive-left {
    width: 500px;
    margin: auto;
  }

  .cognitive-left img {
    width: 100%;
    border-radius: 1rem;
  }

  .source p{
    padding: 1rem 0;
    font-size: 10px;
  }

  @media  screen and (max-width:940px) {

    .cognitive-flex {
      flex-wrap: wrap;
    }

    .cognitive-right {
        width: 100%;
      }

      .cognitive-left {
        width: 100%;
      }

      .cognitive-flex {
        flex-wrap: wrap-reverse;
      }

  }