@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.oxycodone {
    width: 100%;
    height: 80vh;
    background: #00000069;
    position: relative;
  }
  
  .oxycodone:before {
    content: '';
    position: absolute;
    background: url('https://sunset-rehab.s3.us-west-1.amazonaws.com/oxycodone-addiction.jpg') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.oxycodone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.oxycodone-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.oxycodone h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.oxycodone h1 {
    font-size: 2rem;
}

}