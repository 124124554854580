@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.opioid-section {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding-top: 3rem;
}

.opioid-section:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}



.opioid-section-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.opioid-section-left {
  width: 50%;
  margin: auto;
}

.opioid-section-left p {
  text-align: justify;
}


.opioid-section-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.opioid-section-right img {
  width: 100%;
  border-radius: 15px;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .opioid-section-flex {
    flex-wrap: wrap;
  }
  
  .opioid-section-left {
    width: 100%;
    margin: auto;
  }
  
  .opioid-section-right {
    width: 100%;
    margin: auto;
  }

  
  }





