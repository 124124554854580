@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.sa {
    width: 100%;
    height: 80vh;
    background: rgba(21, 3, 27, 0.521);
    position: relative;
  }
  
  .sa:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa-submenu-bg-min.jpg') no-repeat center top/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }
  

.sa {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sa .sa-content {
    display: block;
    position: absolute;
    bottom: 15%;
    padding: 1rem;
}

.sa h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.sa h2 {
    color: white;
    font-size: 1.5rem;
    font-family: 'Roboto Condensed', sans-serif;
    letter-spacing: 1px;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.sa-content h1 {
    font-size: 2rem;
    text-align: center;
}

.sa-content h2 {
    font-size: 1rem;
    text-align: center;
}
}