@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.menu3 {
    padding: 5rem 0;
}
.menu3-header {
    padding: 2rem;
    text-align: center;
}



.menu3-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.menu3-card {
    width: 300px;
    background-color: #759eb8;
}

.menu3-card img {
    width: 100%;
}

.menu3-card-caption {
  padding: 1rem;
  text-align: center;
}


.menu3-card-caption h1 {
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
}



