@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

.hero {
    height:100vh;
    width: 100%;
    background-color: rgba(1, 5, 24, 0.486);
    z-index: 5555;
}

video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;

  }

.hero .hero-container {
    width: 100%;
    bottom: 20%;
    position: absolute;
    text-align: center;
    padding: 1rem;
}

.hero-logo {
    text-align: center;
}

.hero h1 {

    font-size: 3.5rem;
    text-align: center;
    color: white;
    font-family: 'Libre Baskerville', serif;
}

.hero h2 {
    font-size: 2rem;
    color: rgb(229, 231, 229);
    padding: .5rem 0;
}

.hero-container p {
    font-size: 18px;
    color: white;
}

.hero .hero-btn button {
    cursor: pointer;
}






/* MEDIA QUERIES */

@media screen and (max-width:700px) {

    .hero h2 {
        font-size: 18px;
    }

    .hero h1 {
    font-size: 2rem;
    }

    .hero p {
        font-size: 12px;
    }

    .hero h3 {
        font-size: 1rem;
    }


}


@media screen and (max-width: 940px) {
    .hero .hero-container {
    width: 100%;
    bottom: 10%;
    right: 0%;
}
}