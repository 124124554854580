@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapyMH {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .TherapyMH:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.TherapyMH .content {
    top: 10%;
    padding: 5rem 0 ;
    text-align: center;
}



/* HEADER */

.TherapyMH .TherapyMH-header {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

.TherapyMH .left-content {
    top: 15%;
}

.TherapyMH .TherapyMH-header h1{
    color: #fd9b2f;
    font-size: 1rem;
    letter-spacing: 8px; 
}

.TherapyMH .TherapyMH-header p {
    color: rgb(26, 25, 25);
    font-size: 3rem;
    letter-spacing: 5px;
}


/* CARD */


.mh-flex {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.mh-flex .box-icon {
    font-size: 3rem;
    color: #fd9b2f;
    padding: 0;
}

.mh-flex h1 {
    color: black;
    font-size: 1.2rem;
    padding: 1rem 0;
}

.mh-flex p {
    color: #adadad;
}

.mh-flex .card {
    width: 35%;
    padding: 1rem;
}

/* button */

.TherapyMH-btn {
    width: 100%;
    text-align: center;
}

.TherapyMH-cta {
    position: relative;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    justify-content: center;
   }
   
   .TherapyMH-cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #fd9b2f;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
   }
   
   .TherapyMH-cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #171818;
   }
   
   .TherapyMH-cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #171818;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
   }
   
   .TherapyMH-cta:hover:before {
    width: 100%;
    background: #fd9b2f;
    ;
   }
   
   .TherapyMH-cta:hover svg {
    transform: translateX(0);
   }
   
   .TherapyMH-cta:active {
    transform: scale(0.95);
   }



@media screen and (max-width:940px) {


    .mh-flex{
        flex-wrap: wrap;
    }

    .mh-flex .card {
        width: 90%;
        -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        padding: 1rem;
    }

    .TherapyMH .TherapyMH-header p {
        font-size: 2rem;
        letter-spacing: 0;
    }


}


