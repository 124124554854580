@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapySA {
    width: 100%;
    height: auto;
    position: relative;
    background: #1C3B48;
}
  

.TherapySA .content {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    top: 10%;
    padding: 5rem 0;
}

.box-icon {
    font-size: 6rem;
    color: rgb(139, 85, 4);
    padding: 1rem 0;
}


/* LEFT */

.TherapySA .TherapySA-left {
    width: 500px;
    margin: auto;
    padding: 1rem;
}

.TherapySA .left-content {
    top: 15%;

}

.TherapySA .TherapySA-left h1{
    color: rgb(243, 237, 237);
    font-size: 2rem;
    padding: 2rem 0;
}

.TherapySA .TherapySA-left p {
    color: rgb(201, 197, 197);
    font-size: 15px;
    text-align: left;
}


/* RIGHT */

.box-icon {
    width: 100%;
}

 .TherapySA-right {
    width: 800px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.TherapySA-right .box {
    height: auto;
    width: 350px;
    background-color: rgba(24, 23, 23, 0.247);
    padding: .5rem ;
}


/* box 1 */

.box1 .box-content1 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
    text-align: center;
    width: 100%;
    padding: 1rem;

}

.box1 .box-content1 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 2 */

.box2 .box-content2 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box2 .box-content2 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 3 */

.box3 .box-content3 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box3 .box-content3 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}

/* box 4 */

.box4 .box-content4 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
}

.box4 .box-content4 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
}


/* button */

.TherapySA-btn {
    width: 100%;
    text-align: center;
}

.TherapySA-cta {
    position: relative;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    justify-content: center;
   }
   
   .TherapySA-cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #a16423;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
   }
   
   .TherapySA-cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #ebf1f1;
   }
   
   .TherapySA-cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #eff5f5;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
   }
   
   .TherapySA-cta:hover:before {
    width: 100%;
    background: #a16423;
    ;
   }
   
   .TherapySA-cta:hover svg {
    transform: translateX(0);
   }
   
   .TherapySA-cta:active {
    transform: scale(0.95);
   }



.hide-btn {
    display: none;
}

@media screen and (max-width:1400px) {
    .TherapySA .TherapySA-left {
        width: 100%;
    }

    .hide {
        display: none;
    }

    .hide-btn {
        display: block;
        padding: 2rem 0;
    }


    .TherapySA .content {
        flex-wrap: wrap;
        text-align: center;
        padding: 1rem;
    }

    .TherapySA .content p {
        text-align: center;
        padding: 0 1rem;
    }

}


@media screen and (max-width:940px) {


    .TherapySA-right  .box{
        width: 90vw;
    }
    
    .TherapySA .TherapySA-left h1{
        font-size: 2rem;
    }

    .TherapySA .TherapySA-left {
        width: 90vw;
    }

    .TherapySA .TherapySA-right {
        width: 90%;
    }


    
}

